.Header {
	position: fixed;
	z-index: 12;
	display: flex;
	align-items: center;
	width: 100%;
	padding: 25px 5%;
	background: var(--mainBackground);

	@media (max-height: 650px) {
		padding: 20px 5%;
	}

	.header-act-logo {
		width: 160px;
		min-width: 44px;
		@media (max-height: 650px) {
			max-width: 135px;
		}
		picture,
		img {
			display: block;
			width: 100%;
		}
	}

	.hamburguer-btn {
		position: relative;
		z-index: 1;
		width: 45px;
		height: 45px;

		@media (max-height: 650px) {
			width: 44px;
			height: 44px;
		}
		picture,
		img {
			display: block;
		}
	}

	@media (max-height: 650px) {
		.LanguageSwitcher {
			width: 44px;
			height: 44px;
			padding: 14px;

			.languageSwitcherBtn {
				font-size: 13px;
			}
		}
	}

	@media (min-width: 768px) {
		background: transparent;
		padding: 35px 30px 30px;
		.siteNavigation {
			order: 1;
			position: relative;
			color: var(--navTextColorDesktop);

			svg {
				fill: var(--navTextColorDesktop);
				z-index: 10;
			}
		}
		.header-act-logo-wrapper {
			order: 2;
			position: absolute;
			z-index: 12;
			top: 0;
			left: 50%;
			transform: translateX(-50%) translateY(-5px);
			background: var(--mainBackground);
			padding: 26px 15px 15px;
			border-radius: 0 0 30px 30px;
		}
		.ThemeColorToggle {
			order: 3;
		}
		.LanguageSwitcher {
			order: 4;
			margin-right: 0;
		}
	}
}
