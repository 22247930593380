.LanguageSwitcher {
	z-index: 1;
	width: 33px;
	height: 33px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 15px;
	padding: 16px;
	line-height: 1;
	background-color: var(--languageSwitcherBg);
	border-radius: 50%;

	.languageSwitcherBtn {
		color: var(--languageSwitcherTextColor);
		height: 45px;
		text-transform: uppercase;
		width: 45px;
	}
}
