[data-theme='dark'] {
	@media (max-width: 768px) {
		.bg-mobile {
			background-color: var(--navBgDesktop);
		}
	}
}

.siteNavigation {
	&.open {
		.NavMenu {
			display: flex;
			pointer-events: auto;
			overflow-y: scroll;
			@media (max-height: 600px) {
				height: calc(100vh - 66px) !important ;
			}

			.NavMenu-footer {
				display: flex;
			}
			.NavMenu-link {
				justify-content: flex-start;
				svg {
					margin-right: 20px;
				}
			}

			@media (min-width: 768px) {
				width: 370px;
				overflow-y: auto;
				overflow-x: hidden;
				height: calc(100vh - 66px) !important ;
				.NavMenu-footer {
					visibility: visible;
					opacity: 1;
					flex-wrap: wrap;
					flex: 1;
					margin-top: 9vh;
					@media (max-height: 900px) {
						margin-top: 6.5vh;
					}
				}
				.NavMenu-link-wrapper {
					flex: 100%;
					&.active {
						@media (min-width: 768px) {
							span {
								font-size: 18px;
								opacity: 1;
							}
							.NavMenu-link {
								&::after {
									left: -217%;
								}
							}
						}
					}
				}
				.NavMenu-link {
					span {
						line-height: 17px;
						font-size: 18px;
						opacity: 1;
						width: 187px;
						text-align: left;
					}
				}
			}
		}
		.ACTButton {
			&::after {
				@media (min-width: 768px) {
					width: 370px;
					background-color: var(--navBgDesktop);
				}
			}
		}
	}
	&:not(.open) {
		.NavMenu {
			.NavMenu-body {
				.NavMenu-link-wrapper {
					&.active {
						@media (min-width: 768px) {
							span {
								font-size: 0;
								opacity: 0;
							}
						}
					}
				}
			}
		}
	}
	.ACTButton {
		.hamburguer-btn {
			transform: scale(1, -1) rotate(180deg);
			svg {
				width: 100%;
				height: 100%;
			}
		}

		&::after {
			content: '';
			position: absolute;
			top: -70px;
			left: -30px;
			transition: width 300ms ease-in-out;
			@media (min-width: 768px) {
				width: 100px;
				height: 300px;
			}
		}
	}
}

.NavMenu {
	width: 100vw;
	height: calc(100vh - calc(70px + 20%));
	position: absolute;
	top: 100%;
	left: 0;
	display: none;
	flex-direction: column;
	justify-content: space-between;
	padding: 8vh 5% 5%;
	text-align: center;

	@media (max-height: 670px) {
		padding: 2vh 5% 5%;
	}
	@media (max-width: 321px) {
		justify-content: space-around;
	}

	.NavMenu-link-wrapper.active {
		.NavMenu-link {
			@media (max-width: 768px) {
				padding-left: 30px !important;
			}
			span {
				font-size: 18px;
				opacity: 1;

				@media (min-width: 768px) {
					font-size: 0;
					opacity: 0;
				}
			}
		}
	}

	@media (min-width: 768px) {
		display: flex;
		width: 100px;
		height: calc(100vh - 65px);
		min-height: auto;
		min-width: auto;
		left: -30px;
		padding: 8vh 0px 7vh;
		transition: width 300ms ease-in-out;
		@media (max-height: 900px) {
			padding: 6.5vh 0px 35px;
		}
		.NavMenu-footer {
			visibility: hidden;
			opacity: 0;
			transition: opacity 0.4s ease-in-out;
		}

		.NavMenu-link {
			span {
				font-size: 0;
				transition: opacity 0.4s ease-in-out;
				opacity: 0;
			}
		}

		svg {
			fill: var(--navTextColorDesktop);
		}
	}

	.NavMenu-bg-decoration {
		fill: #23a0da;
		position: absolute;
		left: 50%;
		bottom: 14%;
		-webkit-transform: translateX(-50%);
		-moz-transform: translateX(-50%);
		-ms-transform: translateX(-50%);
		-o-transform: translateX(-50%);
		transform: translateX(-50%);
		vertical-align: top;
		width: 100%;
		z-index: 0;

		@media (min-width: 768px) {
			display: none;
		}
	}

	.NavMenu-link {
		width: 100%;
		margin: 0 auto;
		color: var(--textColor);
		padding: 10px 0;
		&:hover {
			color: var(--navActiveTextColor);
		}

		@media (min-width: 768px) {
			width: auto;
			margin: 0;
			color: var(--navTextColorDesktop);
			@media (max-height: 900px) {
				padding: 8px 0;
			}
		}
		@media (max-width: 321px) {
			padding: 5px 0;
		}

		svg {
			width: 30px;
			height: 30px;

			@media (min-width: 768px) {
				width: 26px;
				height: 26px;
				margin-left: 35px;
			}
		}
	}

	.NavMenu-body {
		@media (min-width: 768px) {
			background-color: var(--navBgDesktop);
		}
		padding: 30px 0;
		border-radius: 0 10px 10px 0px;
		.NavMenu-link-wrapper {
			display: flex;
			position: relative;
			z-index: 1;
			margin: 0 auto 5px;
			@media (min-width: 768px) {
				margin: 0 auto 10px;
			}
			&:hover:not(.active) {
				.NavMenu-link {
					color: var(--textColor);
					@media (min-width: 768px) {
						background-color: var(--darkBlue);
						border-radius: 0 300px 300px 0;
						padding-right: 20px;
						transition: background-color 700ms;
						color: white;
						.icon {
							margin-right: 20px;
							margin-left: 35px;
						}
						span {
							font-size: 18px;
							opacity: 1;
							white-space: nowrap;
							transition: opacity 850ms;
						}
					}
				}
			}

			&.active {
				@media (min-width: 768px) {
					width: 300px;
				}
				@media (min-width: 768px) {
					&:hover {
						.NavMenu-link {
							span {
								font-size: 18px;
								opacity: 1;
							}
							&::after {
								@media (min-width: 768px) {
									left: -190%;
								}
							}
						}
					}
				}
				.NavMenu-link {
					color: var(--white);
					z-index: 1;
					position: relative;
					padding-left: 0;
					padding-right: 0;
					@media (min-width: 768px) {
						padding-right: 20px;
					}

					span {
						transition: opacity 850ms;
					}

					&::after {
						content: '';
						position: absolute;
						top: 0;
						left: 0;
						height: 100%;
						width: 100%;
						background-color: var(--darkBlue);
						z-index: -1;
						border-radius: 50px;
						transition: left 700ms;

						@media (min-width: 768px) {
							width: 300%;
							left: -230%;
							padding: 0 100px;
						}
					}

					svg {
						margin-right: 20px;
					}
				}

				svg {
					fill: var(--white);
				}
			}

			&:last-of-type {
				.NavMenu-link {
					margin: 0 auto;

					@media (min-width: 768px) {
						margin: 0;
					}
				}
			}
		}
	}
	.NavMenu-footer {
		justify-content: space-between;

		.contactInfo,
		.socialNetworks {
			position: relative;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			z-index: 1;
			@media (min-width: 768px) {
				position: unset;
				flex-direction: column;
				justify-content: unset;
				flex: 100%;
			}
		}
		.contactInfo {
			a {
				margin: 0;
				padding: 0;
			}
			> p {
				font-size: 14px;
				margin-top: auto;
				text-align: left;
				@media (min-width: 768px) {
					position: absolute;
					width: 100%;
					bottom: 3.5vh;
					text-align: center;
					@media (max-height: 900px) {
						bottom: 3.5vh;
					}
				}
				span {
					color: var(--labelButtonColor);
				}
			}
			.NavMenu-link {
				justify-content: flex-start;
				align-items: center;
			}
			.contactEmail {
				margin: 16px 0 35px;
				&:hover {
					color: var(--navTextColorDesktop);
				}
				svg {
					box-sizing: content-box;
					color: var(--contactIcons);
					margin: 0;
					padding: 7.5px 15px 7.5px 0px;
					transform: scale(1);
					@media (min-width: 768px) {
						padding: 7.5px 15px 7.5px 20px;
					}
				}
				@media (min-width: 768px) {
					margin: 2.5vh 0 0;
					@media (max-height: 900px) {
						margin: 18px 0 0;
					}
				}
				@media (max-width: 321px) {
					margin: 5px 0 15px;
				}
			}
			.contactPhone {
				&:hover {
					color: var(--navTextColorDesktop);
				}
				svg {
					box-sizing: content-box;
					color: var(--contactIcons);
					margin: 0;
					padding: 7.5px 15px 7.5px 0px;
					transform: scale(1);
					@media (min-width: 768px) {
						padding: 7.5px 15px 7.5px 20px;
					}
				}
			}
		}
	}
	.NavMenu-footer {
		justify-content: space-between;

		.socialNetworks {
			justify-content: space-between;
			align-items: center;
			a {
				margin-bottom: 0px;
			}
			@media (min-width: 768px) {
				flex-direction: row;
				margin: 0 60px 10px 28px;
			}
			.NavMenu-link {
				padding: 0;
				justify-content: center;
				display: flex;
				height: 45px;
				width: 45px;
				&:hover {
					color: var(--socialHoverTextColor);
				}

				&:first-of-type {
					margin-top: 0;
				}

				svg {
					margin-right: 0;
					height: 20px;
				}

				&.socialFacebook {
					svg {
						margin-left: 0px;
						width: 20px;
					}
				}

				&.socialLinkedIn,
				&.socialInstagram {
					svg {
						margin-left: 0px;
						width: 20px;
					}
				}

				&.socialTwitter {
					svg {
						margin-left: 0px;
						width: 20px;
					}
				}
			}
		}

		.NavMenu-link {
			margin: 0;
			display: inline-block;
			text-align: left;
			svg {
				vertical-align: middle;
			}
			span {
				vertical-align: middle;
			}
		}
	}
}
.bg-scroll {
	background-color: var(--navBgDesktop);
}
@media (max-width: 768px) {
	.bg-mobile {
		background-color: var(--navBgDesktop);
	}
}
