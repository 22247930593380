*,
*::after,
*::before {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: var(--fontFamily);
	font-size: var(--textFontSize);
	font-weight: var(--textFontWeight);
	line-height: var(--mobileTextLineHeight);
	-webkit-font-smoothing: antialiased;
	@media (max-width: 560px) {
		font-size: var(--mobileTextFontSize);
		line-height: var(--mobileTextLineHeight);
	}
}

body {
	color: var(--textColor);
	background-color: var(--mainBackground);

	@media (min-width: 768px) {
		font-size: var(--textFontSize);
		line-height: var(--textLineHeight);
	}
	&.no-scroll{
		@media (max-width: 768px) {
			overflow: hidden;
		}
	}
}

.container {
	position: relative;
	width: 90%;
	max-width: 1241px;
	margin: 0 auto;
	@media (max-width: 1440px) {
		padding-left: calc((100px - 5%) + 6vw); // Add spacing because of left menu and remove 5% (total width 90% - 5%  would be left space)
	}
	@media (max-width: 767px) {
		padding-left: 0;
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	color: var(--titleColor);
}

button,
a {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	border: none;
	background: none;
	cursor: pointer;
	text-decoration: none;
	color: var(--textColor);

	svg {
		width: 100%;
		height: auto;
	}
}

svg {
	fill: var(--iconsColor);
}

.act-text-highlight {
	cursor: pointer;
	color: var(--highlightText);
}

// This is a utility class to hide elements visually but they are still accessible
.sr-only {
	border: 0 !important;
	clip: rect(1px, 1px, 1px, 1px) !important;
	-webkit-clip-path: inset(50%) !important;
	clip-path: inset(50%) !important;
	height: 1px !important;
	overflow: hidden !important;
	margin: -1px !important;
	padding: 0 !important;
	position: absolute !important;
	width: 1px !important;
	white-space: nowrap !important;
}

.mobile {
	display: none;
	@media (max-width: 768px) {
		display: block;
	}
}

.alternate {
	h2 {
		color: var(--alternateTitleColor);
	}
}