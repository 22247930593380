footer {
	width: 100%;
	position: fixed;
	left: 0;
	bottom: 0;
	background-color: var(--mainFooter);
	padding: 4%;
	@media (min-width: 768px) {
		padding: 30px 10%;
		z-index: 9;
	}

	@media (max-width: 376px) {
		padding: 2%;
	}

	@media (max-height: 800px) {
		padding: 0 10%;
	}

	@media (max-height: 650px) {
		padding: 2% 5%;
	}
	.splide-content {
		width: 100%;
		height: 100%;
		picture {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 100%;
			width: 100%;
			img {
				max-width: 100%;
				max-height: 100%;
				vertical-align: middle;
			}
		}
		.PHP-logo {
			max-width: 70px;
		}
	}
}
