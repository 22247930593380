.Footer {
	position: relative;
	z-index: 10;
	padding: 60px 13% 0 10%;
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-wrap: wrap;
	width: 100%;
	overflow: hidden;
	color: var(--navTextColorDesktop);
	@media (max-width: 1000px) {
		padding: 60px 4% 0 13%;
	}
	@media (max-width: 1199px) {
		flex-direction: column;
		padding: 157px 10% 173px;
	}

	@media (max-height: 650px) {
		padding: 157px 5% 173px;
	}

	.header-act-logo {
		max-width: 175px;
		margin-right: 40px;
		@media (max-width: 850px) {
			max-width: 155px;
			margin-right: 15px;
		}
		@media (max-width: 1199px) {
			margin: 0;
			margin-bottom: 100px;
		}
		picture,
		img {
			display: block;
			width: 100%;
		}
	}

	.top,
	.bottom {
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		width: 100%;
		flex: 100%;
		text-align: center;
		margin-bottom: 40px;
		@media (max-width: 1199px) {
			flex-direction: column;
		}
	}

	.top,
	.bottom {
		@media (max-width: 1199px) {
			margin-bottom: 0;
		}
	}

	.bottom {
		@media (max-width: 850px) {
			margin-bottom: 25px;
		}
	}

	.footer-links {
		display: flex;
		flex-direction: row;
		flex-basis: 60%;
		justify-content: space-between;
		align-items: center;
		max-width: 480px;
		@media (max-width: 1199px) {
			flex-direction: column;
		}
	}
	.footer-link {
		@media (max-width: 1199px) {
			margin-bottom: 30px;
		}

		&.active {
			padding: 10px 20px;
			border-radius: 100px;
			background-color: var(--darkBlue);
			.NavMenu-link {
				color: var(--white);
			}
		}
	}
	.footer-link.last,
	.socials {
		margin-left: auto;
		@media (max-width: 1199px) {
			margin: 0;
		}
	}
	.footer-link.last {
		text-align: right;
		@media (max-width: 1199px) {
			margin-bottom: 100px;
		}
		svg {
			width: 25px;
			height: 25px;
			margin-right: 12px;
			@media (max-width: 1000px) {
				margin-right: 5px;
			}
			@media (max-width: 850px) {
				width: 20px;
				height: 20px;
			}
		}
	}
	.copyright {
		color: var(--navTextColorDesktop);
		flex: 1;
		text-align: left;
		span {
			color: var(--footerCopyrightTextColor);
		}
		@media (max-width: 1199px) {
			order: 1;
			margin-bottom: 20px;
			font-size: 12px;
		}
	}
	.socials {
		display: flex;
		justify-content: flex-end;
		@media (max-width: 1199px) {
			order: 0;
			margin-bottom: 30px;
		}
		& > * {
			margin-right: 20px;
			&:last-of-type {
				margin: 0;
			}
		}
		svg {
			margin-right: 0;
			height: 30px;
		}

		.socialFacebook {
			svg {
				width: 9px;
				height: 18px;
			}
		}

		.socialLinkedIn,
		.socialInstagram {
			svg {
				width: 18px;
				height: 17px;
			}
		}

		.socialTwitter {
			svg {
				width: 19px;
				height: 19px;
			}
		}
	}

	svg {
		fill: var(--navTextColorDesktop);
	}
	a {
		color: var(--navTextColorDesktop);
		@media (max-width: 1280px) {
			span {
				font-size: 14px;
			}
		}
		@media (max-width: 950px) {
			span {
				font-size: 12px;
			}
		}
		@media (max-width: 767px) {
			span {
				font-size: var(--mobileTextFontSize);
			}
			align-items: flex-start;
		}
	}

	.footer-background-one,
	.footer-background-two {
		display: none;
		position: absolute;
		z-index: -1;
		@media (max-width: 1199px) {
			display: block;
		}
	}

	.footer-background-one {
		top: 24px;
		right: -407px;
	}

	.footer-background-two {
		bottom: -142.5px;
		margin-right: -55px;
	}
}
