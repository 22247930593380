:root {
	
	// Generic
	--headerOffsetDesktop: 155px;
	--footerOffsetDesktop: 220px;
	--headerOffsetMobile: 20vh;
	--footerOffsetMobile: 210px;

	// Colors
	--white: #ffffff;
	--light: #f4f4f4;
	--black: #000000;
	--dark: #1f1f1f;
	--dark-menu: #121212;
	--lightGray: #DBE1E4;
	--actGray: #424d55;
	--lightBlue: #189CD9;
	--darkBlue: #184b6f;
	--darkerBlue: #135073;
	--altCyan: #52B5E2;
	--cyan: #23a0da;
	--aquaGreen: #7ccac0;
	--lightGreen: #7ccac0;
	--green: #72C0B7;
	--red: #AD0000;

	// Text
	--fontFamily: 'Gotham', sans-serif;
	--titleFontWeight: 700;
	--titleFontSize: 50px;
	--titleFontSizeTwo: 43px;
	--titleLineHeight: 47.85px;
	--textFontWeight: 400;
	--MediumFontWeight: 500;
	--textFontSize: 18px;
	--textLineHeight: 20px;
	--mediumTextFontSize: 26px;
	--largeTextFontSize: 50px;
	--largeTextFontSizeTwo: 39px;
	--largeLineHeight: 48px;

	// Text Mobile
	--mobileTitleFontSmallSize: 30px;
	--mobileTitleFontSmallHeight: 32px;
	--mobileTitleFontSize: 32px;
	--mobileTitleLineHeight: 32px;
	--mobileTextFontSize: 16px;
	--mobileTextLineHeight: 18px;

	// Light Theme
	--mainBackground: var(--light);
	--mainFooter: var(--light);
	--textColor: var(--dark);
	--arrowColor: var(--green);
	--alternateTitleColor: var(--darkBlue);
	--invertTextColor: var(--white);
	--altTextColor: var(--darkerBlue);
	--invertAltTextColor: var(--green);
	--iconsColor: var(--black);
	--titleColor: var(--darkBlue);
	--title2Color: var(--darkBlue);
	--navBgDesktop: var(--white);
	--navActiveBg: var(--darkBlue);
	--navTextColorFooter: var(--black);
	--navTextColorDesktop: var(--black);
	--navActiveTextColor: var(--white);
	--socialHoverTextColor: var(--darkerBlue);
	--footerCopyrightTextColor: var(--darkerBlue);
	--highlightText: var(--darkBlue);
	--languageSwitcherBg: var(--darkBlue);
	--languageSwitcherTextColor: var(--white);
	--workWithUsBtnBg: var(--lightGreen);
	--workWithUsBtnText: var(--darkBlue);
	--workWithUsHeaderColor: var(--white);
	--btnColor: var(--white);
	--btnBgColor: var(--darkBlue);
	--contactIcons: var(--darkBlue);


	// Fullpage
	--fullpage-nav: var(--cyan);
	--fullpage-nav-active: var(--darkBlue);

	// Projects page
	--svgAltColor: var(--altCyan);

	// Contact page
	--labelButtonColor: var(--darkerBlue);
	--svgVectorOneColor: var(--darkBlue);
	--svgVectorTwoColor: var(--lightGray);
	--alternateContactPageTitle: var(--darkBlue);

	// Vacants page
	--vacantButtonTextColor: var(--white);
	--vacantSubmitButtonTextColor: var(--darkBlue);
	--vacantSvgButtonBg: var(--cyan);

}
	// Breakpoints.
	$breakpoints:(
	mobile-xs:280px,
	mobile-s:350px,
	mobile-m:400px,
	mobile-l:460px,
	mobile-xl:630px,
	tablet-xs:720px,
	tablet-s:768px,
	tablet-m:820px,
	tablet-l:990px,
	tablet-xl:1024px,
	desktop-xs:1180px,
	desktop-s:1440px,
	desktop-m:1680px,
	desktop-l:1920px,
	desktop-xl:2560px,
	);

	// Calc max
@mixin breakpoint($breakpoint, $direction) {
	@if map-has-key($breakpoints, $breakpoint) {
  
	  // Get the breakpoint value.
	  $breakpoint-value: map-get($breakpoints, $breakpoint);
	  
	  @if $direction == max {
		@media (max-width: ($breakpoint-value - 1)) {
		  @content;
		}
	  } @else if $direction == min {      
		@media (min-width: $breakpoint-value) {
		  @content;
		}
	  } 
	
	// If the breakpoint doesn't exist in the map.
	} @else {
	  @if $direction == max {
		@media (max-width: $breakpoint) {
		  @content;
		}
	  } @else if $direction == min {      
		@media (min-width: $breakpoint) {
		  @content;
		}
	  }  
	}
  }
  

// Dark Theme
[data-theme='dark'] {
	
	--mainBackground: var(--dark);
	--mainFooter: var(--dark);
	--textColor: var(--white);
	--arrowColor: var(--green);
	--invertTextColor: var(--dark);
	--altTextColor: var(--green);
	--invertAltTextColor: var(--darkerBlue);
	--iconsColor: var(--white);
	--titleColor: var(--cyan);
	--title2Color: var(--aquaGreen);
	--alternateTitleColor: var(--lightGreen);
	--highlightText: var(--lightGreen);
	--workWithUsBtnBg: var(--darkBlue);
	--workWithUsBtnText: var(--lightGreen);
	--workWithUsHeaderColor: var(--black);
	
	--navBgDesktop: var(--dark-menu);
	--navActiveBg: var(--darkBlue);
	--navTextColorDesktop: var(--white);
	--navTextColorFooter: var(--black);
	--navActiveTextColor: var(--white);
	--socialHoverTextColor: var(--aquaGreen);
	--footerCopyrightTextColor: var(--green);
	--languageSwitcherBg: var(--cyan);
	--languageSwitcherTextColor: var(--black);
	--contactIcons: var(--cyan);

	// Fullpage
	--fullpage-nav: var(--aquaGreen);
	--fullpage-nav-active: var(--cyan);

	// Projects page
	--svgAltColor: var(--darkBlue);
	
	// Contact page
	--labelButtonColor: var(--aquaGreen);
	--svgVectorOneColor: var(--lightBlue);
	--svgVectorTwoColor: var(--darkBlue);
	--alternateContactPageTitle: var(--white);

	// Vacant page
	--vacantButtonTextColor: var(--darkBlue);
	--vacantSubmitButtonTextColor: var(--black);
	--vacantSvgButtonBg: var(--cyan);
	@media (max-width: 767px) {
		--vacantSvgButtonBg: var(--white);
	}

	--btnColor: var(--black);
	--btnBgColor: var(--aquaGreen);
}
